import { Heading, Text } from '@/components';
import { App } from '@/layout';

import * as S from '../styles';

const SEO = {
  title: 'Error 404 // Vinnicius Gomes',
};

export default function Error404() {
  return (
    <App seo={SEO}>
      <S.Container>
        <Heading>four oh four</Heading>
        <Text>This page doesn&apos;t exist.</Text>
      </S.Container>
    </App>
  );
}
